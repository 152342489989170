<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Niche Category List" page-icon="mdi-code-tags">
    <g-list-page
        elevation="elevation-0"
        :headers="headers"
        stateendpoint="nicheCategory.nicheCategories"
        @loadData="loadData"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @destroyItem="destroyItem"
        @switchHandler="switchHandler"
        @restoreItem="restoreItem"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="4">
            <add-button
                text="Niche Category"
                :toolbar="true"
                @onButtonClick="openPopUp"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <component
        is="NicheCategoryPopup"
        ref="component"
        :basic-data="basicData"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import NicheCategoryPopup from "../components/popups/NicheCategoryPopup";
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import AddButton from "../components/buttons/AddButton";
import GPSearch from "../components/GPSearch";
import ListMixins from "../components/mixins/list/List";
import PageOverlay from "../components/PageOverlay";

export default {
  name: "NicheCategories",
  metaInfo: {
    title: "rabbiitfirm.com",
    titleTemplate: "Admin Dashboard - Niche Categories | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" }
    ]
  },
  components: {
    PageOverlay,
    GPSearch,
    AddButton,
    ConfirmPopup,
    GListPage,
    GCard,
    NicheCategoryPopup
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Status",
          value: "isActive"
        },
        {
          text: "",
          value: "actions",
          sortable: false
        }
      ],
      actions: {
        load: "nicheCategory/load",
        store: "nicheCategory/store",
        update: "nicheCategory/update",
        restore: "nicheCategory/restore",
        remove: "nicheCategory/remove",
        destroy: "nicheCategory/destroy"
      },
      basicData: {
        isActive: true
      }
    }
  },
}
</script>
